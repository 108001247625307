<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
    style="margin-bottom: 20px;"
  >
    <!-- <div style="width:25%;display:inline-block;padding:20px;">
      <iframe
        src="https://widget.taggbox.com/89204"
        style="width:100%;height:1200px;border:none;vertical-align: top;display:inline-block;"
      style="margin-bottom: 20px;width:70%;display: inline-block;vertical-align: top;"
      />
    </div> -->
    <v-layout wrap>
      <v-flex
        v-show="viewDashboardImage"
        sm12
        md12
        lg8
        xl6
      >
        <DashboardImage
          class="dashboardTile"
        />
      </v-flex>
      <v-flex
        v-if="viewAreaTicketsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <TicketManagementTile class="dashboardTile" />
      </v-flex>
      <v-flex
        v-if="viewAreaTicketsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <NewTicketsTile class="dashboardTile" />
        <CompletedTicketsTile class="dashboardTile" />
      </v-flex>
      <v-flex
        v-if="viewQuotesAndProjectsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <PMNCountComponent class="dashboardTile" />
        <PMNEmergencyComponent class="dashboardTile" />
      </v-flex>
      <v-flex
        v-if="viewQuotesAndProjectsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <PMNSummary class="dashboardTile" />
      </v-flex>
      <div
        v-if="1===2"
        class=""
      >
        <p>The AC Analytics charts go here...</p>
      </div>
      <v-flex
        v-if="viewQuotesAndProjectsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <PMNDueDate class="dashboardTile" />
      </v-flex>
      <v-flex
        v-if="viewQuotesAndProjectsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <ProjectsStatusTile class="dashboardTile" />
      </v-flex>
      <v-flex
        v-if="viewQuotesAndProjectsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <QuotesStatusTile class="dashboardTile"/>
      </v-flex>
      <v-flex
        v-if="viewAreaTicketsPermission"
        sm12
        md6
        lg4
        xl3
      >
        <HighlightLinkTile
          class="dashboardTile"
          style="margin-bottom: 10px;"
        />
      </v-flex>
      <v-flex
        v-if="viewiQuotePermission"
        sm12
        md6
        lg4
        xl3
      >
        <PricingConfiguratorTile class="dashboardTile" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import DashboardImage from '../components/dashboard/DashboardImage'
import ProjectsStatusTile from '../components/tiles/dashboard/ProjectsStatusTile'
import PMNSummary from '../components/tiles/dashboard/PMNSummaryComponent'
import PMNDueDate from '../components/tiles/dashboard/PMNDueDateComponent'
import PMNCountComponent from '../components/tiles/dashboard/PMNCountComponent'
import PMNEmergencyComponent from '../components/tiles/dashboard/PMNEmergencyComponent'
import QuotesStatusTile from '../components/tiles/dashboard/QuotesStatusTile'
import PricingConfiguratorTile from '../components/tiles/dashboard/PricingConfiguratorTile'
import TicketManagementTile from '../components/tiles/dashboard/TicketManagementTile'
import HighlightLinkTile from '../components/tiles/dashboard/HighlightLinkTile'
import NewTicketsTile from '../components/tiles/dashboard/NewTicketsTile'
import CompletedTicketsTile from '../components/tiles/dashboard/CompletedTicketsTile'
export default {
  metaInfo: {
    title: 'iQuote - Home Page'
  },
  components: {
    PricingConfiguratorTile,
    DashboardImage,
    TicketManagementTile,
    HighlightLinkTile,
    NewTicketsTile,
    CompletedTicketsTile,
    ProjectsStatusTile,
    PMNSummary,
    PMNDueDate,
    PMNCountComponent,
    PMNEmergencyComponent,
    QuotesStatusTile
  },
  data () {
    return {
      tabs: 0
    }
  },
  computed: {
    isiQuoteUser () {
      var uid = this.$store.getters.userId
      return ((uid > 21 && uid < 27) || uid > 40)
    },
    viewAreaTicketsPermission () {
      return this.$store.getters.viewAreaTicketsPermission
    },
    viewAreasPermission () {
      return this.$store.getters.viewAreasPermission
    },
    viewiQuotePermission () {
      return this.$store.getters.viewiQuotePermission
    },
    viewQuotesAndProjectsPermission () {
      return this.$store.getters.viewQuotesAndProjectsPermission
    },
    viewDashboardImage () {
      return this.$store.getters.isDashboardImageVisible
    },
    maintelTesting () {
      return this.$store.getters.maintelTesting
    }
  }
}
</script>
<style scoped>
  .dashboardTile {
    display: block;
  }
</style>
